import React from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import Button from '../Button'
import Link from '../Link'
import { isLoggedIn } from '../../services/auth'

const ModalExitRow = ({ linkPrimary, linkSecondary }) => {
  return (
    <ModalExitContainer>
      <Button link={linkPrimary} text='Save and Leave' type='primary' target={isLoggedIn() && '_blank'}/>
      <Link href={linkSecondary} text='Leave Now' type='primary'/>
    </ModalExitContainer>
  )
}

export default ModalExitRow

const ModalExitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-start: 30px;
  margin-block-end: 15px;
  row-gap: 20px;

  ${mq.tablet} {
    column-gap: 40px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    row-gap: 0;
  }
`
