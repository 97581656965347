import React, { useRef } from 'react'
import { Vector3 } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { PerspectiveCamera } from '@react-three/drei'
import noise from 'noise-library'

const AnimatedOrb = ({ color1, color2, color3 }) => {
  const sphere = useRef()
  const shadow = useRef()
  const shadow2 = useRef()

  const color = color1;
  const dcolor = color2;
  const pcolor = color3;

  const intensity = 1;

  useFrame(() => {
    animate()
  })

  const update = (mesh, delay = 1) => {
    const k = 0.5;
    const v3 = new Vector3();
    const time = (performance.now() * 0.0003) / delay;
    const positions = mesh.current.geometry.attributes.position;

    for(let i = 0; i < positions.count; i++) {
      const n = noise.perlin3(v3.x * k + time, v3.y * k + time, v3.z * k + time);
      v3.fromBufferAttribute(positions, i);
      v3.setLength(1 + 0.4 * n);
      positions.setXYZ(i, v3.x, v3.y, v3.z);
    }
    positions.needsUpdate = true;

    mesh.current.geometry.computeVertexNormals(); // don't forget to call this
  }

  const animate = time => {
    time *= 0.0005; // convert time to seconds
    update(sphere);
    update(shadow, 2);
    update(shadow2, 4);
  };

  return (
    <>
      <PerspectiveCamera
        makeDefault
        fov={40}
        aspect={window.innerWidth / window.innerHeight}
        near={0.1}
        far={50}
        position={[0, 0, 5]}
      />
      <directionalLight color={color} position={[1, 1, 1]} intensity={intensity * 1.5}/>
      <directionalLight color={dcolor} position={[-1, -2, 1]} intensity={intensity * 1 }/>
      <directionalLight color={pcolor} position={[0, -5, -3]} intensity={intensity * 2}/>

      <mesh
        ref={sphere}
      >
        <sphereGeometry args={[1, 128, 128]} />
        <meshLambertMaterial color={0xffffff} opacity={1} transparent={true}/>

        <mesh ref={shadow} scale={[1.6, 1.6, 1.6]} position={[0.2, 0, -2]}>
          <sphereGeometry args={[1, 128, 128]} />
          <meshLambertMaterial color={0xffffff} opacity={0.3} transparent={true}/>
        </mesh>

        <mesh ref={shadow2} scale={[0.65, 0.65, 0.65]} position={[-0.2, 0, 2]}>
          <sphereGeometry args={[1, 128, 128]} />
          <meshLambertMaterial color={0xffffff} opacity={0.3} transparent={true}/>
        </mesh>
      </mesh>
    </>
  )
}

export default AnimatedOrb
