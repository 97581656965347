import React from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import OrbImage from '../OrbImage';
import Rtf from '../Rtf/Article'

const BenchmarkChapter = ({ data }) => {
  return (
    <ChapterContainer>
      <OrbImageWrapper>
        <OrbImage type={data.field_theme_key}/>
      </OrbImageWrapper>
      <ChapterCopyWrapper>
        <ChapterHeading>{data.field_selection_title}</ChapterHeading>
        <ChapterCopy>
          <Rtf copy={data.field_chapter_teaser}/>
        </ChapterCopy>
      </ChapterCopyWrapper>
    </ChapterContainer>
  )
}

export default BenchmarkChapter

const ChapterContainer = styled.div`
  display: flex;
  flex-basis: 45%;
  align-items: flex-start;
  justify-items: center;
`

const ChapterCopyWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-block-start: 10px;
  max-width: 240px;

  ${mq.desktop} {
    max-width: 310px;
    align-items: flex-start;
  }
`

const ChapterHeading = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  margin-block-end: 5px;
`

const ChapterCopy = styled.div`
  text-align: left;

  ${mq.desktop} {
    text-align: left;
  }
`

const OrbImageWrapper= styled.div`
  margin: 10px 20px 0px 0px;
  max-inline-size: 75px;
  align-self: flex-start;
  max-width: 60px;

  ${mq.desktop} {
    max-width: 80px;
    margin: 10px 22px 0px 0px;
    width: 65px;
  }
`
