import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { colors, mq } from '../../styles/theme'
import OrbImage from '../OrbImage';

const RelatedResources = props => {
  const {
    field_prgph_heading_plain_text, relationships, nodes
  } = props

  const relatedResourcesList = relationships?.field_manually_selected_resource

  const goalsArray = list => {
    const goals = []

    list.map(el => {
      return goals.push(el.name)
    })
    return goals.join(' | ')
  }

  const resourceElements = nodes?.length > 0 ? nodes : relatedResourcesList

  return (
    <RelatedResourcesWrapper>
      <RelatedResourcesHeading>{field_prgph_heading_plain_text}</RelatedResourcesHeading>
      <ResourceCardContainer>
        {resourceElements && resourceElements.map((el, i) => {
          const link = el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? `/resources${el.path.alias}` : el.relationships.field_resource.field_external_resource_link.uri
          const target = el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? '_self' : '_blank'

          return (
            <ResourceCardLink href={link} target={target} key={i}>
              <ResourceCard>
                <OrbImageWrapper>
                  <OrbImage type={el.relationships.field_goal_topic_labels?.[0]?.relationships?.field_theme?.field_theme_key}/>
                </OrbImageWrapper>
                <RelatedResourcesUpper>
                  <ResourceCardSource>
                    {el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? 'The Ohio State University' : el.relationships.field_resource.field_source}
                  </ResourceCardSource>
                  <ResourceCardHeading>{el.field_heading_plain_text}</ResourceCardHeading>
                </RelatedResourcesUpper>
                <RelatedResourcesBottom>
                  <ResourceCardType>{el.relationships.field_content_types_labels?.name}</ResourceCardType>
                </RelatedResourcesBottom>
              </ResourceCard>
            </ResourceCardLink>
          )
        })}
      </ResourceCardContainer>
      <Button type='primary' text='View All Resources' link='/resources' />
    </RelatedResourcesWrapper>
  )
}

export default RelatedResources

const RelatedResourcesWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-block: 80px;
  margin-inline: 20px;

  ${mq.desktop} {
    margin-inline: 80px;
  }
`

const RelatedResourcesHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 1.07;
  margin-block-end: 40px;
  text-align: center;

  ${mq.tablet} {
    font-size: 40px;
  }
`

const RelatedResourcesUpper = styled.div``

const RelatedResourcesBottom = styled.div`
  display: flex;
  flex-direction: column;
  max-inline-size: 250px;
`

const ResourceCardContainer = styled.div`
  column-gap: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-block-end: 40px;
  max-inline-size: 1600px;
  row-gap: 20px;
  width: 100%;

  ${mq.desktop} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`

const ResourceCard = styled.div`
  background-color: ${colors.white};
  background-size: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-block-size: 183px;
  padding: 40px 30px;
  overflow: hidden;
  position: relative;

  ${mq.tablet} {
    max-inline-size: 400px;
    margin: auto;
    background-size: 100px;
    min-block-size: 271px;
  }
`

const OrbImageWrapper= styled.div`
  width: 180px;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(35%,45%);
  z-index: 1;
  transition: all 0.4s ease-in-out;
`

const ResourceCardHeading = styled.h3`
  color: ${colors.black};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;

  ${mq.tablet} {
    font-size: 23px;
  }
`

const ResourceCardLink = styled.a`
  text-decoration: none;
  width: 100%;

  &:hover {
    ${OrbImageWrapper}{
      transform: translate(35%,45%) scale(1.2) rotateZ(15deg);
    }
    ${ResourceCardHeading}{
      color: ${colors.primary}
    }
  }
`

const ResourceCardSource = styled.h6`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 13px;
  margin-block-end: 5px;
`

const ResourceCardType = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 12px;
`

const ResourceCardGoalLabel = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 12px;
`
