import { useStaticQuery, graphql } from 'gatsby'

export const useExitValidationModal = () => {
  const { allNodeExitValidationModal } = useStaticQuery(
    graphql`
      query exitValidation {
        allNodeExitValidationModal(filter: {status: {eq: true}}) {
          nodes {
            field_heading_plain_text
            field_copy
          }
        }
      }
    `
  )
  return allNodeExitValidationModal.nodes
}
