import React from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import PreviewOrb from './PreviewOrb'

const PreviewRow = ({heading, copy, chapters}) => {
  return (
    <PreviewRowSection>
      <PreviewLeftCol>
        <PreviewHeading>{heading}</PreviewHeading>
        <PreviewCopy>{copy}</PreviewCopy>
      </PreviewLeftCol>
      <PreviewRightCol>
        {chapters.map((el, i) => (
          <PreviewOrb key={i} data={el} />
        ))}
      </PreviewRightCol>
    </PreviewRowSection>
  )
}

export default PreviewRow


const PreviewRowSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  pading-inline: 20px;
  pading-bottom: 60px;
  width: 100%;

  ${mq.desktop} {
    padding-top: 100px;
    pading-inline: 80px;
    pading-bottom: 100px;
    flex-direction: row;
  }
`

const PreviewLeftCol = styled.div`
  flex: 1;
`

const PreviewRightCol = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 40px;

  ${mq.tablet} {
    flex-direction: row;
    row-gap: 35px;
  }
`

const PreviewHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  margin-block-end: 20px;

  ${mq.desktop} {
    font-size: 42px;
  }
`

const PreviewCopy = styled.p`
  margin-block-end: 20px;
`
