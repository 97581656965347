import React, { useEffect, useState, useCallback } from 'react'
import Layout from '../components/Layout/layout'
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { createQuestionnaire, fetchChapterInfo, getChapterAnswers, getQuestionnairePreview, getStatus, patchChapter, submitChapter } from '../services/questionnaire';
import { Form, Formik } from 'formik';
import FormController from '../components/Form/FormController';
import Wave from '../components/Wave';
import FeaturedContentRow from '../components/FeaturedContentRow';
import Preloader from '../components/Preloader';
import WideContainer from '../components/WideContainer/widecontainer';
import ContactGrid from '../components/ContactGrid';
import PreviewRow from '../components/QuestionnairePreview';
import ChapterProgressBar from '../components/ChapterProgressBar';
import { mq } from '../styles/theme';
import RelatedResources from '../components/RelatedResources'
import ResourceMatches from '../components/ResourceMatches';
import Modal from '../components/Modal/index'
import ExitModal from '../components/Modal/ModalExitRow'
import { isLoggedIn } from '../services/auth';
import { useLocation } from '@reach/router';
import { useExitValidationModal } from '../utils/queries/exitValidationModal';

const Questionnaire = props => {
  const { data } = props
  const [questionnaire, setQuestionnaire] = useState(null);
  const [currentElement, setCurrentElement] = useState(null);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [exitAnimation, setExitAnimation] = useState(0);
  const [headerChaptersInfo, setHeaderChaptersInfo] = useState(null);
  const [questionnaireStatus, setQuestionnaireStatus] = useState(null);
  const [questionnairePreview, setQuestionnairePreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const [chapterNavigation, setChapterNavigation] = useState(null);
  const [resourceMatches, setResourceMatches] = useState(0);
  const [showExitModal, setShowExitModal] = useState(false)
  const [clickedUrl, setClickedUrl] = useState('')
  const [previousChapter, setPreviousChapter] = useState(null);
  const [multilineChapters, setMultilineChapters] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [loadingChapters, setLoadingChapters] = useState(true);
  const [conditionExecuted, setConditionExecuted] = useState(false);

  let internalChapters = []

  const questionnaireInitialInfo = data?.allNodeQuestionnaire.nodes.length ? data.allNodeQuestionnaire.nodes[0] : null;

  const introElements = [
    'osu_introduction_chapter_element',
    'osu_radio_with_icon',
    'osu_questionnaire_intro_element',
    'osu_chapter_transition_element'
  ]

  const {
    field_copy,
    field_heading_plain_text
  } = useExitValidationModal()[0]

  const handlePageClick = useCallback(e => {
    e.preventDefault()
    setClickedUrl(e.target?.closest('a')?.href)
    setShowExitModal(true)
    return true
  }, []);

  const location = useLocation().pathname.replace(/^\/+/g, '');

  // Gatsby - Reach Router cannot block navigation or detect the event
  // Using a workaround for page leave detection
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelectorAll('a').forEach(el => {
        el.addEventListener('click', handlePageClick);
      })
    }, 3000)
    return () => clearTimeout(timer);
  }, [handlePageClick]);

  /** Save resource association after trying to leave the form **/
  useEffect(() => {
    if (showExitModal) {
      setPreviousChapter({ ...currentChapter })
    }
  }, [showExitModal]);

  const [formInitialValues, setFormInitialValues] = useState({});

  const getChapterElements = chapter => {
    chapter.elements = []
    Object.keys(chapter).forEach(key => {
      if (key.charAt(0) !== '#' && key !== 'elements') {
        chapter.elements.push(chapter[key])
      }
    })
    chapter.elements = chapter.elements.sort((a, b) => {
      return a.weight - b.weight;
    });

    chapter.elements.forEach(element => {
      // eslint-disable-next-line default-case
      switch (element['#type']) {
      case 'osu_questionnaire_intro_element':
        if (!localStorage.getItem('disclaimerRead')) {
          setLoadingChapters(false);
        }
        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          checkbox: element['#checkbox_copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          banner: element['#help_banner']
        }
        break;

      case 'osu_radio_with_icon':
        const radioOptions = []
        Object.keys(element['#options']).forEach(key => {
          const info = element['#options'][key].split(' -- ')

          radioOptions.push({
            'id': key,
            'heading': info[0] ?? '',
            'copy': info[1] ?? '',
            'hover_img': info[2] ?? '',
            'default_img': info[3] ?? ''
          })
        })

        element.props = {
          heading: element['#title'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          options: radioOptions
        }
        break;
      case 'osu_boolean_element':
        const booleanOptions = []

        Object.keys(element['#options']).forEach(key => {
          booleanOptions.push({
            'key': key,
            'value': element['#options'][key]
          })
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          options: booleanOptions
        }
        break;
      case 'osu_single_select_element':
        const singleSelectOptions = []

        Object.keys(element['#options']).forEach(key => {
          singleSelectOptions.push({
            'key': key,
            'value': element['#options'][key]
          })
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          options: singleSelectOptions
        }
        break;
      case 'osu_introduction_chapter_element':
        element.props = {
          chapter: chapter['#chapter_heading'],
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`
        }
        break;
      case 'osu_multiline_element':
        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`
        }

        setMultilineChapters(prevState => {
          if (chapter['#chapter_id'] in prevState) {
            if (!prevState[chapter['#chapter_id']].includes(`chapter_${chapter['#chapter_id']}__${element['#name']}`)) {
              prevState[chapter['#chapter_id']].push(`chapter_${chapter['#chapter_id']}__${element['#name']}`)
            }
          } else {
            prevState[chapter['#chapter_id']] = []
            prevState[chapter['#chapter_id']].push(`chapter_${chapter['#chapter_id']}__${element['#name']}`)
          }

          return { ...prevState }
        })
        break;
      case 'osu_multiselect_element':
        const multiselectOptions = []
        Object.keys(element['#options']).forEach(key => {
          multiselectOptions.push({
            'key': key,
            'value': element['#options'][key]
          })
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          options: multiselectOptions
        }
        break;
      case 'osu_chapter_selection_element':
        const chaptersOptions = []
        internalChapters.forEach(internalChapter => {
          chaptersOptions.push({
            id: `${internalChapter['#chapter_id']}`,
            heading: internalChapter['#chapter_heading'] ?? '',
            copy: internalChapter['#chapter_teaser'] ?? ''
          })
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter-selection-${chapter['#chapter_id']}`,
          options: chaptersOptions
        }

        setFormInitialValues(prevState => {
          return {
            ...prevState,
            'chapter-selection-id': chapter['#chapter_id']
          }
        })
        break;
      case 'osu_range_element':
        const rangeOptions = []
        Object.keys(element['#options']).forEach(key => {
          const info = element['#options'][key].split(' -- ')

          rangeOptions.push({
            'id': key,
            'title': info[0] ?? '',
            'tooltip': info[1] ?? ''
          })
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`,
          options: rangeOptions
        }
        break;
      case 'osu_singleline_element':

        setFormInitialValues(prevState => {
          prevState[`chapter_${chapter['#chapter_id']}__${element['#name']}`] = ''
          return { ...prevState }
        })

        element.props = {
          heading: element['#title'],
          copy: element['#copy'],
          name: `chapter_${chapter['#chapter_id']}__${element['#name']}`
        }
        break;
      case 'osu_chapter_transition_element':
        element.props = {
          heading: element['#title'],
          chapters: 0,
          duration_in_minutes: 0
        }
        break;
      }
    })
  }

  const getOptionalModules = questionnaireInitialInfo => {
    let optionalModules = {}

    if (questionnaireInitialInfo.relationships?.field_optional_modules?.length) {
      questionnaireInitialInfo.relationships.field_optional_modules.forEach((module) => {
        if (module.paragraph_type.drupal_internal__target_id) {
          switch (module.paragraph_type.drupal_internal__target_id) {
          case 'featured_content_row_with_cta':
            optionalModules['featured_content_row_with_cta'] = {
              heading: module.field_prgph_heading_plain_text,
              copy: module.field_prgph_copy,
              image: module.relationships.field_prgph_image.relationships.field_media_image.localFile,//'https://verndale-image-tools.herokuapp.com/id/8KfCR12oeUM?w=1440&h=750',
              cta: {
                text: module.field_prgph_link_tab.title,
                link: module.field_prgph_link_tab.uri
              },
              contact: {
                text: 'or call',
                phone: module.field_phone_number
              },
              orientation: module.field_content_orientation || ''
            }
            break;
          case 'contact_grid':
            const contact_grid_list = []
            if (module.relationships?.field_contact_items?.length) {
              module.relationships.field_contact_items.forEach((item) => {
                contact_grid_list.push({
                  heading: item.field_prgph_heading_plain_text,
                  sub_heading: item.field_prgph_sub_headi,
                  phone: item.field_phone,
                  email: item.field_email,
                  email_label: item.field_label_email_1,
                  image: item.relationships.field_contact_image.relationships.field_media_image.localFile
                })
              })
            }
            optionalModules['contact_grid'] = {
              heading: module.field_prgph_heading_plain_text,
              list: contact_grid_list
            }
            break;
          }
        }
      })
    }

    return optionalModules
  }

  const getHeaderChaptersInfo = (chapters, questionnaireStatus) => {
    const headerChapters = []

    chapters.forEach((chapter, index) => {
      const intro = () => {
        if (chapter['#chapter_type'] === 'paragraph__intro_chapter') {
          return true
        }
        return false
      }

      const selected = () => {
        if (questionnaireStatus?.chapters_enabled?.length) {
          if (questionnaireStatus.chapters_enabled.includes(chapter['#chapter_id'])) {
            return true
          }
        }

        if (chapter['#chapter_type'] === 'paragraph__basic_chapter') {
          return true
        }

        return false
      }

      const progress = () => {
        if (questionnaireStatus.chapters_not_started.includes(chapter['#chapter_id'])) {
          return 'Not Started'
        }

        if (questionnaireStatus.chapters_completed.includes(chapter['#chapter_id'])) {
          return 'Completed'
        }

        if (questionnaireStatus.chapter_submission_drafts.find((item) => {
          return item.chapter_id === chapter['#chapter_id']
        })) {
          return 'In Progress'
        }
      }

      let chapterIndex = chapter['#index']

      questionnaire.selected_chapters.forEach((selectedChapter, selectedChapterIndex) => {
        if (selectedChapter['#index'] === chapter['#index']) {
          chapterIndex = selectedChapterIndex
        }
      })

      headerChapters.push({
        index: chapterIndex,
        id: chapter['#chapter_id'],
        heading: intro() ? chapter['#chapter_heading'] : `Section ${chapter['#index'] + 1 - questionnaire.intro_chapters}: ${chapter['#chapter_heading']}`,
        progress: progress(),
        intro: intro(),
        selected: selected(),
        theme: chapter['#theme'],
        chapterType: chapter['#chapter_type']
      })
    })

    if (currentChapter && questionnaire) {
      setHeaderChaptersInfo({
        chapters: headerChapters,
        current_chapter: {
          id: currentChapter['#chapter_id'],
          heading: currentChapter['#index'] + 1 > questionnaire.intro_chapters ? `Section ${currentChapter['#index'] + 1 - questionnaire.intro_chapters}: ${currentChapter['#chapter_heading']}` : currentChapter['#chapter_heading'],
          theme: currentChapter['#theme'],
          chapterType: currentChapter['#chapter_type']
        }
      })
    }
  }

  /**
   * Actions when user goes to the next chapter
   */
  const next = (waitTime = 500, lastStatus = null) => {

    switch (currentElement?.['#type']) {
    case 'osu_singleline_element':
      waitTime = 2500
      break;
    case 'osu_radio_with_icon':
    case 'osu_introduction_chapter_element':
    case 'osu_boolean_element':
    case 'osu_multiline_element':
    case 'osu_multiselect_element':
      waitTime = 1200
      break;
    case 'osu_range_element':
      waitTime = 1000
      break;
    }

    setExitAnimation(1)
    setTimeout(() => {
      setExitAnimation(0)

      setQuestionnaire(currentState => {

        let next_element = currentState.next_element
        let next_chapter = currentState.next_chapter
        let previous_chapter = currentState.current_chapter
        let previous_element = currentState.current_element

        if (next_chapter === currentState.current_chapter && next_element === currentState.current_element) {
          //TODO end questionnaire
          window.location = '/questionnaire/results';
        }

        if (currentState.selected_chapters?.[currentState.next_chapter]?.elements.length - 1 >= currentState.next_element + 1) {
          next_element = currentState.next_element + 1
        } else {
          if (next_element === currentState.next_element) {
            if (currentState.selected_chapters.length - 1 >= currentState.next_chapter + 1) {
              next_chapter = currentState.next_chapter + 1
              next_element = 0
            }
          }
        }

        if (introElements.includes(currentElement['#type'])) {
          previous_chapter = currentState.previous_chapter
          previous_element = currentState.previous_element
        }

        //Update selected chapter transition element
        if (currentElement['#type'] === 'osu_chapter_selection_element') {

          if (!lastStatus) {
            lastStatus = questionnaireStatus
          }

          let selectedChapters = currentState.selected_chapters
          selectedChapters = []

          currentState.chapters.forEach((chapter) => {
            if (chapter['#chapter_type'] != 'paragraph__interior_chapter') {
              selectedChapters.push(chapter)
            }
            if (lastStatus.chapters_enabled.includes(chapter['#chapter_id'])) {
              selectedChapters.push(chapter)
            }
          })

          //Update props for chapter transition element
          const element = currentState.selected_chapters?.[currentState.next_chapter]?.elements?.[currentState.next_element]

          if (element['#type'] === 'osu_chapter_transition_element') {
            let chapters = 0,
              duration_in_minutes = 0;

            currentState.chapters.forEach((chapter) => {

              if (lastStatus.chapters_enabled.includes(chapter['#chapter_id'])) {
                chapters += 1
                duration_in_minutes += chapter['#minutes_to_read']
              }
            })

            element.props = {
              heading: element.props.heading,
              chapters,
              duration_in_minutes
            }
          }
        }

        return {
          chapters: currentState.chapters,
          selected_chapters: currentState.selected_chapters,
          current_chapter: currentState.next_chapter,
          current_element: currentState.next_element,
          next_chapter,
          next_element,
          previous_chapter,
          previous_element,
          intro_chapters: currentState.intro_chapters,
          internal_chapters: currentState.internal_chapters,
          basic_chapters: currentState.basic_chapters,
          optional_modules: currentState.optional_modules
        }
      })

    }, waitTime)
  }

  /**
   * Actions when user goes back
   */
  const back = () => {
    setExitAnimation(1)
    setConditionExecuted(false);
    setTimeout(() => {
      setExitAnimation(0)

      setQuestionnaire(currentState => {

        let previous_chapter = currentState.previous_chapter
        let previous_element = currentState.previous_element

        let next_chapter = currentState.current_chapter
        let next_element = currentState.current_element

        let chapter_index = currentState.previous_chapter
        let element_index = currentState.previous_element

        if (element_index === 0) {
          if (chapter_index - 1 > currentState.intro_chapters - 1) {
            chapter_index -= 1;
            element_index = currentState.selected_chapters?.[chapter_index]?.elements?.length - 1
          }
        }

        chapters:
          for(let i = chapter_index; i >= 0; i--) {
            if (i > currentState.intro_chapters - 1) {
              for(let j = element_index - 1; j >= 0; j--) {
                if (currentState.selected_chapters?.[i]?.elements?.[j]?.['#type'] !== 'osu_introduction_chapter_element') {
                  previous_chapter = i
                  previous_element = j
                  break chapters;
                }
              }
            }
          }

        if (currentState.selected_chapters?.[currentState.previous_chapter]?.elements.length - 1 >= currentState.previous_element + 1) {
          next_element = currentState.previous_element + 1
          next_chapter = currentState.previous_chapter
        } else {
          if (currentState.selected_chapters.length - 1 >= currentState.previous_chapter + 1) {
            next_chapter = currentState.previous_chapter + 1
            next_element = 0
          }
        }

        if (currentState.previous_chapter != next_chapter) {
          next_element = 0
        }

        return {
          chapters: currentState.chapters,
          selected_chapters: currentState.selected_chapters,
          current_chapter: currentState.previous_chapter,
          current_element: currentState.previous_element,
          next_chapter,
          next_element,
          previous_chapter,
          previous_element,
          intro_chapters: currentState.intro_chapters,
          internal_chapters: currentState.internal_chapters,
          basic_chapters: currentState.basic_chapters,
          optional_modules: currentState.optional_modules
        }
      })

    }, 1500)
  }

  /**
   *  Get resources matches of a chapter
   */
  const getResourceMatches = () => {
    let requestBody = {
      webform_id: currentChapter['#webform_id'],
      questionnaire_id: questionnaireInitialInfo.drupal_internal__nid
    }

    let chapterSubmissionId

    if (questionnaireStatus?.chapter_submissions.length) {
      chapterSubmissionId = questionnaireStatus.chapter_submissions.find((item) => {
        return item.chapter_id === currentChapter['#chapter_id']
      });
    }

    if (chapterSubmissionId && chapterSubmissionId.chapter_submission_id) {
      patchChapter(requestBody, chapterSubmissionId.chapter_submission_id).then((response) => {
        getStatus().then(status => {
          setQuestionnaireStatus(status)
          setResourceMatches(response?.resource_matches || 0)
          setLoadingChapters(false)
        }).catch(e => {
          console.error('Error trying to get questionnaire status', e.message)
        })
      }).catch(e => {
        console.error('Error trying to save questionnaire', e)
      })

    } else {
      requestBody['chapter_id'] = currentChapter['#chapter_id']

      submitChapter(requestBody).then((response) => {
        getStatus().then(status => {
          setQuestionnaireStatus(status)
          setResourceMatches(response?.resource_matches || 0)
          setLoadingChapters(false);
        }).catch(e => {
          console.error('Error trying to get questionnaire status', e.message)
        })
      }).catch(e => {
        console.error('Error trying to save questionnaire', e)
      })
    }
  }

  /**
   * Actions when user goes a specific chapter
   */
  const goToChapter = chapter_index => {
    setExitAnimation(1)
    setTimeout(() => {
      setExitAnimation(0)

      setQuestionnaire(currentState => {
        let previous_chapter = chapter_index - 1;
        let previous_element = currentState.selected_chapters?.[chapter_index - 1]?.elements?.length - 1

        chapters:
          for(let i = chapter_index - 1; i >= 0; i--) {
            if (i > currentState.intro_chapters - 1) {
              for(let j = previous_element; j >= 0; j--) {
                if (!introElements.includes(currentState.selected_chapters?.[i]?.elements?.[j]?.['#type'])) {
                  previous_chapter = i
                  previous_element = j
                  break chapters;
                }
              }
            }
          }

        return {
          chapters: currentState.chapters,
          selected_chapters: currentState.selected_chapters,
          current_chapter: chapter_index,
          current_element: 0,
          next_chapter: chapter_index,
          next_element: 1,
          previous_chapter,
          previous_element,
          basic_chapters: currentState.basic_chapters,
          intro_chapters: currentState.intro_chapters,
          internal_chapters: currentState.internal_chapters,
          optional_modules: currentState.optional_modules
        }
      })
    }, 1500)
  }

  const getProgress = (totalElements, currentElement) => {
    const percentage = ((currentElement) * 100) / totalElements
    return percentage.toFixed(2)
  }

  const getNavigation = () => {
    if (questionnaire && currentChapter) {
      let basicChapter = false

      if (currentChapter['#chapter_type'] === 'paragraph__basic_chapter') {
        basicChapter = true
      }

      let backToChapter = questionnaire.current_element === 0

      if (questionnaire.current_element > 0 && questionnaire.selected_chapters?.[questionnaire.current_chapter]?.elements?.[questionnaire.current_element - 1]?.['#type'] === 'osu_introduction_chapter_element') {
        backToChapter = true
      }

      let backButtonLabel = questionnaire.selected_chapters?.[questionnaire.previous_chapter]?.elements?.[questionnaire.previous_element]?.['#title']
      if (backToChapter && !basicChapter) {
        backButtonLabel = questionnaire.selected_chapters?.[questionnaire.previous_chapter]?.['#chapter_heading']
      }

      if (backToChapter && basicChapter) {
        backButtonLabel = null
      }

      return {
        back_button_label: backButtonLabel
      }
    }

    return {}
  }

  const createQuestionnaireAndGetStatus = () => {
    createQuestionnaire(questionnaireInitialInfo.drupal_internal__nid).then(response => {
      getStatus().then(status => {
        setQuestionnaireStatus(status)
      }).catch(e => {
        console.error('Error trying to get questionnaire status', e)
      })
    }).catch(e => {
      console.error('Error trying to create a questionnaire', e)
    })
  }

  const mapSubmissionsAndGetAnswers = submissions => {
    const notNeededKeys = ['chapter_id', 'questionnaire_id', 'questionnaire_sub_id', 'in_draft']
    submissions.forEach(submission => {
      getChapterAnswers(submission.webform_id, submission.chapter_submission_uuid).then(answers => {
        let filteredAnswers = {}
        if (answers?.data && Object.keys(answers?.data).length) {
          Object.keys(answers.data).forEach(key => {
            if (!notNeededKeys.includes(key)) {
              if (submission.webform_id === 'intro_chapter') {
                filteredAnswers[`chapter_${submission.chapter_id}__${key}`] = answers.data[key] === '1' ? [`chapter_${submission.chapter_id}__${key}`] : []
              } else {
                filteredAnswers[`chapter_${submission.chapter_id}__${key}`] = answers.data[key]
              }
            }
          })
          setFormInitialValues(prevState => {
            return { ...prevState, ...filteredAnswers }
          })
          setFormValues(prevState => {
            return { ...prevState, ...filteredAnswers }
          })
        }
      }).catch((e) => {
        console.error('Error trying to retrieve questionnaire answers', e)
      })
    })
  }

  const getActiveQuestionnaire = () => {
    getStatus().then(status => {
      if (!Object.keys(status.questionnaire).length) { // No active questionnaire
        createQuestionnaireAndGetStatus()
      } else { // Active questionnaire
        let activeQuestionnaireId = status.questionnaire.questionnaire_id?.[0]?.target_id
        if (activeQuestionnaireId && activeQuestionnaireId !== questionnaireInitialInfo.drupal_internal__nid) {
          createQuestionnaireAndGetStatus()
        } else {
          setQuestionnaireStatus(status)
          mapSubmissionsAndGetAnswers(status.chapter_submissions)
        }
      }
    }).catch(e => {
      console.error('Error trying to get questionnaire status', e)
    })
  }

  //#region Init
  /** Init **/
  useEffect(() => {

    if (questionnaireInitialInfo) {

      getQuestionnairePreview(questionnaireInitialInfo.drupal_internal__nid).then(response => {
        setQuestionnairePreview(response)
      }).catch(e => {
        console.error('Error trying to get questionnaire preview', e.message)
      })

      let chapters = questionnaireInitialInfo.relationships.field_chapters
      const introPromises = []
      const internalPromises = []
      const basicPromises = []
      let chapterInfo = {}

      chapters.forEach((c, index) => {
        chapterInfo = {
          '#chapter_id': `${c.drupal_internal__id}`,
          '#chapter_heading': c.field_prgph_heading_plain_text,
          '#chapter_type': c.internal.type,
          '#chapter_teaser': c.field_chapter_teaser,
          '#minutes_to_read': c.field_minutes_to_read,
          '#theme': c.relationships?.field_theme?.field_theme_key,
          '#chapter_resources': c.relationships?.field_chapter_settings?.relationships?.field_chapter_resources,
          '#index': index
        }

        if (c.internal.type === 'paragraph__intro_chapter') {
          chapterInfo['#webform_id'] = c.field_webform_intro_chapter.drupal_internal__target_id
          introPromises.push(fetchChapterInfo(c.field_webform_intro_chapter.drupal_internal__target_id, chapterInfo))
        }

        if (c.internal.type === 'paragraph__interior_chapter') {
          chapterInfo['#webform_id'] = c.field_webform_interior_chapter.drupal_internal__target_id
          internalPromises.push(fetchChapterInfo(c.field_webform_interior_chapter.drupal_internal__target_id, chapterInfo))
        }

        if (c.internal.type === 'paragraph__basic_chapter') {
          chapterInfo['#webform_id'] = c.field_webform_interior_chapter.drupal_internal__target_id
          basicPromises.push(fetchChapterInfo(c.field_webform_interior_chapter.drupal_internal__target_id, chapterInfo))
        }
      })

      const getChapters = async () => {
        let intro = await Promise.all(introPromises),
          basic = await Promise.all(basicPromises);
        internalChapters = await Promise.all(internalPromises);

        intro = intro.sort((a, b) => {
          return a.weight - b.weight;
        });

        internalChapters = internalChapters.sort((a, b) => {
          return a.weight - b.weight;
        });

        basic = basic.sort((a, b) => {
          return a.weight - b.weight;
        });

        intro.forEach(chapter => {
          getChapterElements(chapter)
        })

        internalChapters.forEach(chapter => {
          getChapterElements(chapter)
        })

        basic.forEach(chapter => {
          getChapterElements(chapter)
        })

        chapters = [...intro, ...basic, ...internalChapters]

        const next_element = chapters?.[0]?.elements?.[1] ? 1 : 0
        const next_chapter = next_element === 0 ? 1 : 0

        setQuestionnaire({
          chapters,
          selected_chapters: chapters,
          current_chapter: 0,
          current_element: 0,
          next_chapter,
          next_element,
          previous_chapter: 0,
          previous_element: 0,
          intro_chapters: intro.length,
          basic_chapters: basic.length,
          internal_chapters: internalChapters.length,
          optional_modules: getOptionalModules(questionnaireInitialInfo)
        })
      }

      getChapters().then(() => {
        getActiveQuestionnaire()
      }).catch(e => {
        console.error('Error trying to get questionnaire chapters information', e)
      })
    }
  }, []);
  //#endregion

  /** On current chapter changes **/
  useEffect(() => {
    if (currentChapter) {
      const idx = currentChapter['#index'];
      const disclaimerRead = localStorage.getItem('disclaimerRead');

      if (idx > 0 && !disclaimerRead) {
        localStorage.setItem('disclaimerRead', 'true');
      }

      if (idx === 0 && disclaimerRead) {
        goToChapter(1);
      } else if (!introElements.includes(currentElement?.['#type'])) {
        getResourceMatches();
      }
    }
  }, [currentChapter]);

  //#region on previous chapter change
  /** Save resource association after switching chapters **/
  useEffect(() => {
    if (previousChapter && multilineChapters && Object.keys(multilineChapters).length && formValues && questionnaireStatus) {
      if (previousChapter['#chapter_id'] in multilineChapters) {
        const chapterSubmissionInfo = questionnaireStatus.chapter_submissions.find((chapter) => {
          return chapter.chapter_id === previousChapter['#chapter_id']
        })

        if (chapterSubmissionInfo?.chapter_resource_id) {
          const chapterResources = previousChapter['#chapter_resources'].find((resources) => {
            return resources.drupal_internal__id === parseInt(chapterSubmissionInfo.chapter_resource_id)
          })

          if (chapterResources) {
            const keywordAssociationScores = []
            let matchCount = 0

            chapterResources.relationships.field_resources.forEach((resource) => {
              if (resource.field_keywords.length) {
                matchCount = 0

                resource.field_keywords.forEach(keyword => {
                  multilineChapters[previousChapter['#chapter_id']].forEach(element => {
                    const regex = new RegExp(keyword, 'gim');
                    let match = formValues?.[element]?.match(regex);

                    if (match?.length) {
                      matchCount++
                    }
                  })
                })

                if (matchCount) {
                  keywordAssociationScores.push({
                    key: resource.drupal_internal__nid,
                    value: matchCount
                  })
                }
              }
            })

            let requestBody = {
              webform_id: previousChapter['#webform_id'],
              questionnaire_id: questionnaireInitialInfo.drupal_internal__nid,
              keyword_association_scores: keywordAssociationScores
            }

            if (chapterSubmissionInfo?.chapter_submission_id) {
              patchChapter(requestBody, chapterSubmissionInfo.chapter_submission_id).catch(e => {
                console.error('Error trying to save questionnaire keyword associations', e)
              })
            }
          }
        }
      }
    }
  }, [previousChapter]);
  //#endregion

  /** On current chapter and questionnaire status change **/
  useEffect(() => {
    if (questionnaire && currentChapter) {
      setChapterNavigation(getNavigation())
    }
  }, [currentChapter, questionnaire]);

  useEffect(() => {
    if (currentElement) {
      // Timeout for chapter selection transition
      if (currentElement?.['#type'] === 'osu_chapter_transition_element') {
        setTimeout(() => {
          next(1200)
        }, 3000)
      }
    }
  }, [currentElement]);

  //#region on questionnaire change
  /** On questionnaire change **/
  useEffect(() => {
    if (questionnaire) {
      setCurrentElement(questionnaire.selected_chapters?.[questionnaire.current_chapter]?.elements?.[questionnaire.current_element])
      setCurrentChapter(prevState => {
        const current = questionnaire.selected_chapters?.[questionnaire.current_chapter]

        if (current?.['#chapter_id'] !== prevState?.['#chapter_id']) {
          setPreviousChapter(prevState)
        }

        return current
      })
      setProgress(getProgress(questionnaire.selected_chapters?.[questionnaire.current_chapter]?.elements.length, questionnaire.current_element))
    }
  }, [questionnaire]);
  //#endregion

  //#region on questionnaire status change
  useEffect(() => {
    if (questionnaireStatus) {
      setQuestionnaire(currentState => {

        const selectedChapters = []
        questionnaire.chapters.forEach((chapter) => {
          if (chapter['#chapter_type'] != 'paragraph__interior_chapter') {
            selectedChapters.push(chapter)
          } else {
            if (questionnaireStatus.chapters_enabled.length && questionnaireStatus.chapters_enabled.includes(chapter['#chapter_id'])) {
              selectedChapters.push(chapter)
            }
          }
        })

        currentState.selected_chapters = selectedChapters

        return { ...currentState }
      })

      setFormInitialValues(prevState => {
        prevState[`chapter-selection-${prevState['chapter-selection-id']}`] = questionnaireStatus.chapters_enabled
        return {
          ...prevState
        }
      })
    }
  }, [questionnaireStatus]);
  //#endregion

  useEffect(() => {
    if (questionnaire, questionnaireStatus) {
      getHeaderChaptersInfo(questionnaire.chapters, questionnaireStatus)
    }
  }, [questionnaireStatus, questionnaire, currentChapter]);

  const onSubmit = values => {
    setFormValues(values)
    const currentChapterLastElement = currentChapter.elements.length - 1
    let requestBody = {
      webform_id: currentChapter['#webform_id'],
      questionnaire_id: questionnaireInitialInfo.drupal_internal__nid
    }
    if (currentElement === currentChapter.elements[currentChapterLastElement]) {
      requestBody = {
        ...requestBody,
        in_draft: [{ value: false }]
      }
    }

    if ((values.chapter_162__have_you_ever_experienced_this_kind_of_event_ === 'No' && !conditionExecuted) || (values.chapter_151__have_you_ever_experienced_this_kind_of_event_ === 'No' && !conditionExecuted) || (values.chapter_173__have_you_ever_experienced_this_kind_of_event_ === 'No' && !conditionExecuted)) {
      goToChapter(currentChapter['#index'] + 1);
      setConditionExecuted(true);
      return;
    }



    // eslint-disable-next-line default-case
    switch (currentElement['#type']) {
    case 'osu_questionnaire_intro_element':
      if (values[`chapter_${currentChapter['#chapter_id']}__${currentElement['#name']}`].length) {
        requestBody[currentElement['#name']] = true
      } else {
        requestBody[currentElement['#name']] = false
      }
      break;
    case 'osu_chapter_selection_element':
      requestBody['chapters'] = values[`chapter-selection-${currentChapter['#chapter_id']}`]
      break;
    case 'osu_radio_with_icon':
    case 'osu_boolean_element':
    case 'osu_single_select_element':
    case 'osu_multiline_element':
    case 'osu_multiselect_element':
    case 'osu_range_element':
    case 'osu_singleline_element':
      if (values[`chapter_${currentChapter['#chapter_id']}__${currentElement['#name']}`]) {
        requestBody[currentElement['#name']] = values[`chapter_${currentChapter['#chapter_id']}__${currentElement['#name']}`]
      }
      break;
    }

    let chapterSubmissionId

    if (questionnaireStatus?.chapter_submissions.length) {
      chapterSubmissionId = questionnaireStatus.chapter_submissions.find((item) => {
        return item.chapter_id === currentChapter['#chapter_id']
      });
    }

    if (chapterSubmissionId?.chapter_submission_id) {
      patchChapter(requestBody, chapterSubmissionId.chapter_submission_id).then((response) => {
        getStatus().then(status => {
          setQuestionnaireStatus(status)
          setResourceMatches(response?.resource_matches || 0)
          next(500, status)
          setLoadingChapters(false);
        }).catch(e => {
          console.error('Error trying to get questionnaire status', e.message)
        })
      }).catch(e => {
        console.error('Error trying to save questionnaire', e)
      })

    } else {
      requestBody['chapter_id'] = currentChapter['#chapter_id']

      submitChapter(requestBody).then((response) => {
        getStatus().then(status => {
          setQuestionnaireStatus(status)
          setResourceMatches(response?.resource_matches || 0)
          next(500, status)
          setLoadingChapters(false);
        }).catch(e => {
          console.error('Error trying to get questionnaire status', e.message)
        })
      }).catch(e => {
        console.error('Error trying to save questionnaire', e)
      })
    }
  }

  const isChapterBasicOrIntro = currentChapter?.['#chapter_type'] === 'paragraph__intro_chapter' || currentChapter?.['#chapter_type'] === 'paragraph__basic_chapter'

  return (
    <Layout path={'questionnaire'} headerTagline={questionnaireInitialInfo?.field_heading_plain_text} headerChaptersInfo={headerChaptersInfo} goToChapter={goToChapter}>
      <Modal hasBackgroundOpacity onClose={() => setShowExitModal(false)} show={showExitModal} title={field_heading_plain_text}>
        {field_copy}
        <ExitModal linkPrimary={!isLoggedIn() ? `/app/login?from=${location}` : clickedUrl} linkSecondary={clickedUrl}/>
      </Modal>
      {loadingChapters && <Preloader></Preloader>}
      {questionnaire &&
        <QuestionnaireContainer>

          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={onSubmit}
          >
            {formik => (
              <Form>
                <FormWrapper>
                  {!introElements.includes(currentElement?.['#type']) &&
                    <ChapterProgressBar theme={isChapterBasicOrIntro ? null : currentChapter?.['#theme']} progress={progress} chapterNavigation={chapterNavigation} next={next}
                      back={back}/>
                  }
                  {introElements.includes(currentElement?.['#type']) ?
                    <FormController
                      key={`${questionnaire.current_chapter}-${questionnaire.current_element}`}
                      control={currentElement?.['#type']}
                      {...currentElement?.props}
                      next={next}
                      formik={formik}
                      exit_animation={exitAnimation}
                      theme={currentChapter?.['#theme']}
                    /> : <FormElementsContainer resourceMatches={!!resourceMatches}>
                      <FormController
                        key={`${questionnaire.current_chapter}-${questionnaire.current_element}`}
                        control={currentElement?.['#type']}
                        {...currentElement?.props}
                        next={next}
                        formik={formik}
                        exit_animation={exitAnimation}
                        is_last_element={questionnaire.selected_chapters?.[questionnaire.current_chapter]?.elements.length - 1 === questionnaire.current_element ? 1 : 0}
                        next_chapter_label={questionnaire.next_chapter != questionnaire.current_chapter ? `Continue to Chapter ${questionnaire.next_chapter + 1 - questionnaire.intro_chapters}: ${questionnaire.selected_chapters?.[questionnaire.next_chapter]?.['#chapter_heading']}` : 'Continue'}
                        theme={currentChapter?.['#theme']}
                      />
                      {resourceMatches > 0 &&
                        <ResourceMatches key={`resource-matches-${questionnaire.current_chapter}-${questionnaire.current_element}`} currentElement={currentElement} resourceMatches={resourceMatches} chapterType={currentChapter?.['#chapter_type']} theme={currentChapter?.['#theme']} exit_animation={exitAnimation}/>
                      }
                    </FormElementsContainer>
                  }
                </FormWrapper>
                {introElements.includes(currentElement?.['#type']) &&
                  <Wave left={100} top={-400}/>
                }

              </Form>
            )}
          </Formik>

          <WideContainer>
            {questionnaire.optional_modules && 'featured_content_row_with_cta' in questionnaire.optional_modules &&
              <FeaturedContentRow {...questionnaire.optional_modules['featured_content_row_with_cta']}/>
            }

            {questionnairePreview?.length &&
              <PreviewRow heading={questionnaireInitialInfo?.field_heading_plain_text} copy={questionnaireInitialInfo?.field_tooltip_description}
                chapters={questionnairePreview}/>
            }

            <RelatedResources {...data.paragraphRelatedResources}{...data.allNodeResource}/>

            {questionnaire.optional_modules && 'contact_grid' in questionnaire.optional_modules &&
              <ContactGrid {...questionnaire.optional_modules['contact_grid']}/>
            }
          </WideContainer>

        </QuestionnaireContainer>
      }
    </Layout>
  )
}

export default Questionnaire

export { Head } from '../components/_GatsbyPageHead'

const QuestionnaireContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FormElementsContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.resourceMatches ? 'row' : 'column'};
  column-gap: 20px;
  width: 100%;
  min-block-size: 650px;
  max-block-size: calc(1000px - 199px);
  block-size: calc(100vh - 134px);
  overflow-y: auto;
  align-items: ${props => props.resourceMatches ? 'unset' : 'center'};
  justify-content: ${props => props.resourceMatches ? 'space-between' : 'unset'};
  max-width: unset;
  padding: 0px 20px;
  margin: auto;

  ${mq.tablet} {
    padding: 0px 80px;
    max-width: 1440px;
    block-size: calc(100vh - 199px);
  }
`

export const query = graphql`
query Questionnaire($QuestionnaireId: Int!, $ResourceTopic: Int!, $RelatedResourcesId: String!)
  {
    ...RelatedResources
    ...DynamicResources
    allNodeQuestionnaire(filter:
    {
      drupal_internal__nid: {
        eq: $QuestionnaireId
      }
    })
    {
      nodes
      {
        title
        drupal_internal__nid,
        field_heading_plain_text,
        field_tooltip_description,
        relationships
        {
          node_type
          {
            name
          }
          field_chapters
          {
          ...
            on
            paragraph__interior_chapter
            {
              field_webform_interior_chapter
              {
                drupal_internal__target_id
              }
              field_prgph_heading_plain_text
              field_chapter_teaser
              field_minutes_to_read
              field_theme
              {
                drupal_internal__target_id
              }
              internal
              {
                type
              }
              drupal_internal__id
              relationships {
                field_theme {
                  name
                  field_theme_key
                }
                field_chapter_settings {
                  relationships {
                    field_chapter_resources {
                      drupal_internal__id
                      relationships {
                        field_resources {
                          drupal_internal__nid
                          field_keywords
                        }
                      }
                    }
                  }
                }
              }
            }
          ...
            on
            paragraph__intro_chapter
            {
              field_prgph_heading_plain_text
              field_webform_intro_chapter
              {
                drupal_internal__target_id
              }
              internal
              {
                type
              }
              drupal_internal__id
              relationships {
                field_theme {
                  name
                  field_theme_key
                }
              }
            }
          ...
            on
            paragraph__basic_chapter
            {
              field_prgph_heading_plain_text
              field_chapter_teaser
              field_webform_interior_chapter
              {
                drupal_internal__target_id
              }
              internal
              {
                type
              }
              drupal_internal__id
              relationships {
                field_theme {
                  name
                  field_theme_key
                }
                field_chapter_settings {
                  relationships {
                    field_chapter_resources {
                      drupal_internal__id
                      relationships {
                        field_resources {
                          drupal_internal__nid
                          field_keywords
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_optional_modules {
          ... on paragraph__contact_grid {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_prgph_heading_plain_text
            relationships {
              field_contact_items {
                field_email
                field_label_email_1
                field_phone
                field_prgph_heading_plain_text
                field_prgph_sub_headi
                relationships {
                  field_contact_image {
                    relationships {
                      field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 100, height: 100)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__featured_content_row_with_cta {
            field_prgph_heading_plain_text
            field_prgph_copy
            field_content_orientation
            field_prgph_link_tab {
              title
              uri
              newtab
            }
            field_phone_number
            paragraph_type {
              drupal_internal__target_id
            }
            relationships {
              field_prgph_image {
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                            gatsbyImageData(width: 1024)
                          }
                    }
                  }
                }
              }
            }
          }
        }
        }
      }
    }
  }
`
