import React from 'react'
import styled from 'styled-components'
import { mq, colors } from '../../styles/theme'
import leftChevron from '../../assets/chevron-left.svg'
import { handleThemeColor } from '../../utils/colorHandler';

const ChapterProgressBar = ({ progress, chapterNavigation, theme, next, back }) => {
  // Default progress bar color
  const progress_bar_colors = handleThemeColor(theme || '')

  return (
    <ChapterProgressBarContainer>
      <ProgressBarContainer>
        <ProgressBar progress={progress} theme={progress_bar_colors}/>
      </ProgressBarContainer>
      <ChapterProgressBarNavigation>
        <ChapterProgressBarBack
          onClick={back}
        >
          {chapterNavigation?.back_button_label &&
            <ChapterProgressBarBackWrapper>
              <ChapterProgressBarBackChevron/>
              <ChapterProgressBarBackLabel>{chapterNavigation?.back_button_label}</ChapterProgressBarBackLabel>
            </ChapterProgressBarBackWrapper>
          }
        </ChapterProgressBarBack>
        <ChapterProgressBarSkip
          onClick={next}
        >Skip for now</ChapterProgressBarSkip>
      </ChapterProgressBarNavigation>
    </ChapterProgressBarContainer>
  )
}

export default ChapterProgressBar

const ChapterProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  max-width: unset;
  padding: 20px 20px;
  margin: auto;
  width: 100%;

  ${mq.tablet} {
    padding: 20px 80px 45px;
    max-width: 1440px;
  }
`

const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  background-color: ${colors.midGray};
  border-radius: 3px;
`

const ProgressBar = styled.div`
  position: relative;
  background-image: linear-gradient(to right, ${props => props.theme.start}, ${props => props.theme.middle}) ;
  border-radius: 3px;
  height: 5px;
  width: ${props => props.progress}%;
  transition: width .8s ease-in;
`

const ChapterProgressBarNavigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
`

const ChapterProgressBarBack = styled.div`
  cursor: pointer;
`

const ChapterProgressBarBackWrapper = styled.div`
  display: flex;
`

const ChapterProgressBarBackChevron = styled.div`
  height: 14px;
  width: 14px;
  background-image: url(${leftChevron});
  margin-top: 5px;
  margin-right: 5px;
`

const ChapterProgressBarBackLabel = styled.p`
  color: ${colors.primary};
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;

  ${mq.tablet} {
    max-width: 300px;
  }
`

const ChapterProgressBarSkip = styled.div`
  color: ${colors.primary};
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
`
