import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import Link from '../Link';
import AnimatedOrb from '../Orb/animatedOrb';
import { Canvas } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { handleThemeColor } from '../../utils/colorHandler';

const ResourceMatches = ({ resourceMatches, theme, chapterType, ...rest }) => {

  const isChapterBasicOrIntro = chapterType === 'paragraph__intro_chapter' || chapterType === 'paragraph__basic_chapter'
  let orbColors = handleThemeColor((isChapterBasicOrIntro ? '' : theme) || '')

  const commonAnimation = {
    x: {
      duration: 1
    },
    opacity: {
      duration: 1
    },
    // eslint-disable-next-line quote-props
    default: {
      ease: 'linear'
    }
  };

  const animateShow = {
    opacity: 1,
    x: 0
  };

  const animateHide = {
    opacity: 0,
    x: -2000
  };

  const animateTransition = {
    delay: 0.2,
    ...commonAnimation
  };

  const animateInitial = {
    opacity: 0,
    x: 1000
  };

  return (
    <ResourceMatchesContainer
      initial={animateInitial}
      animate={rest.exit_animation ? animateHide : animateShow}
      transition={animateTransition}
    >
      <ResourceMatchesHeading>
        We are building your recommendations...
      </ResourceMatchesHeading>
      <ResourceMatchesCount>
        {resourceMatches &&
          `${resourceMatches} resource matches`
        }
      </ResourceMatchesCount>
      <ResourceMatchesOrbContainer>
        <AnimatedOrb color1={orbColors.start} color2={orbColors.middle} color3={orbColors.end}/>
      </ResourceMatchesOrbContainer>
      <ResourceMatchesCopy>
        The more information you provide, the more tailored the resources will be to your needs.
      </ResourceMatchesCopy>
      <ResourceMatchesViewRecomendations text={'View current recommendations'} href='/questionnaire/results' type={'primary'} target='__blank'/>
    </ResourceMatchesContainer>
  )
}

export default ResourceMatches

const ResourceMatchesContainer = styled(motion.div)`
  width: 477px;
  display: none;
  background-color: ${colors.gray};
  margin-bottom: 55px;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ResourceMatchesHeading = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 60px 40px 10px;
`

const ResourceMatchesCount = styled.p`
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  margin: 10px 40px 20px;
`

const ResourceMatchesOrbContainer = styled(Canvas)`
  width: 230px;
  height: auto;
`

const ResourceMatchesCopy = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 60px 80px 20px;
`

const ResourceMatchesViewRecomendations = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 20px 80px;
`
